/*
 * The Typekit service used to deliver this font or fonts for use on websites
 * is provided by Adobe and is subject to these Terms of Use
 * http://www.adobe.com/products/eulas/tou_typekit. For font license
 * information, see the list below.
 *
 * baker-signet-web:
 *   - http://typekit.com/eulas/00000000000000000001007e
 *
 * © 2009-2021 Adobe Systems Incorporated. All Rights Reserved.
 */
/*{"last_published":"2021-05-10 14:11:14 UTC"}*/

@font-face {
    font-family: "baker-signet-web";
    src        : url("https://use.typekit.net/af/83600b/00000000000000000001007e/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),
        url("https://use.typekit.net/af/83600b/00000000000000000001007e/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),
        url("https://use.typekit.net/af/83600b/00000000000000000001007e/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
    font-display  : auto;
    font-style    : normal;
    font-weight   : 400;
}

@font-face {
    font-family : "Dolce Vita";
    src         : url("./dolce-vita/Dolce Vita.ttf");
    src         : url("./dolce-vita/Dolce Vita Light.ttf");
    font-display: auto;
    font-style  : normal;
    font-weight : bold;
}

@font-face {
    font-family : "Dolce Vita Heavy";
    src         : url("./dolce-vita/Dolce Vita Heavy Bold.ttf");
    font-display: auto;
    font-style  : normal;
    font-weight : bold;
}

@font-face {
    font-family : "Trebuchet MS";
    src         : url("./trebuchet-ms/Trebuchet-MS-Italic.ttf");
    src         : url("./trebuchet-ms/trebuchet-ms.ttf");
    src         : url("./trebuchet-ms/trebuc.ttf");
    font-display: auto;
    font-style  : normal;
    font-weight : bold;
}